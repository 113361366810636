<template>
  <div class="measure-thresthold-container">
    <div class="setting-container">
      <div class="setting-title">
        {{ t('HS001.title') }}
      </div>
      <div class="setting-line"></div>
      <div class="setting-warning">
        {{ t('HS001.subtitle') }}
      </div>
      <div class="setting-content">
        <a-row>
          <a-col :span="10" class="option-area">
            <div class="option-up">
              <a-checkbox v-model:checked="checkEPass">
                {{ t('HS001.ePass') }}
              </a-checkbox>
              <a-button
                type="link"
                shape="circle"
                size="small"
                @click="showEpassWarning"
              >
                <template #icon><InfoCircleOutlined /></template>
              </a-button>
            </div>
            <div class="option-up">
              <a-checkbox v-model:checked="checkNFCCard">
                {{ t('HS001.nfcCard') }}
              </a-checkbox>
            </div>
            <div class="option-up">
              <a-checkbox v-model:checked="checkMyKad">
                {{ t('HS001.myKad') }}
              </a-checkbox>
            </div>
            <!-- <div class="option-up">
              <a-checkbox v-model:checked="checkRocId">健保卡</a-checkbox>
            </div> -->
            <div class="option-line"></div>
            <div class="member-tip">{{ t('HS001.enableMemberId') }}</div>
            <div class="member-check">
              <a-switch v-model:checked="checkMemberList" />
              <span style="margin-left: 11px">{{ t('HS001.memberId') }}</span>
            </div>
          </a-col>
          <a-col :span="14" class="option-preview">
            <div class="preview-text">{{ t('HS001.preview') }}</div>
            <div class="setting-image-content">
              <a-image
                class="setting-image-bg"
                :preview="false"
                :src="getBgImgUrl()"
              />
              <template v-if="checkCount == 2">
                <div class="image-2-1">
                  <a-image
                    :width="125"
                    :height="145"
                    :preview="false"
                    :src="getCheckedImage(0)"
                  ></a-image>
                </div>
                <div class="image-2-2">
                  <a-image
                    :width="125"
                    :height="145"
                    :preview="false"
                    :src="getCheckedImage(1)"
                  ></a-image>
                </div>
              </template>
              <template v-if="checkCount == 3">
                <div class="image-3-1">
                  <a-image
                    :width="125"
                    :height="145"
                    :preview="false"
                    :src="getCheckedImage(0)"
                  ></a-image>
                </div>
                <div class="image-3-2">
                  <a-image
                    :width="125"
                    :height="145"
                    :preview="false"
                    :src="getCheckedImage(1)"
                  ></a-image>
                </div>
                <div class="image-3-3">
                  <a-image
                    :width="125"
                    :height="145"
                    :preview="false"
                    :src="getCheckedImage(2)"
                  ></a-image>
                </div>
              </template>
              <template v-if="checkCount == 4">
                <div class="image-4-1">
                  <a-image
                    :width="95"
                    :preview="false"
                    :src="getCheckedImage(0)"
                  ></a-image>
                </div>
                <div class="image-4-2">
                  <a-image
                    :width="95"
                    :height="105"
                    :preview="false"
                    :src="getCheckedImage(1)"
                  ></a-image>
                </div>
                <div class="image-4-3">
                  <a-image
                    :width="95"
                    :height="105"
                    :preview="false"
                    :src="getCheckedImage(2)"
                  ></a-image>
                </div>
                <div class="image-4-4">
                  <a-image
                    :width="95"
                    :height="105"
                    :preview="false"
                    :src="getCheckedImage(3)"
                  ></a-image>
                </div>
              </template>
              <!-- <a-image
                v-for="(item, index) in getCheckedImage()"
                :key="index"
                :with="100"
                :src="item"
              /> -->
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="button-area">
        <a-button class="cancel-btn" @click="onCancel">
          {{ t('LM001.cancel') }}
        </a-button>
        <a-button class="save-btn" @click="onSave" :disabled="isDisableSave">
          {{ t('LM001.save') }}
        </a-button>
      </div>
    </div>
    <a-modal
      v-model:visible="isShowEpassWarning"
      @ok="handleOk"
      :footer="null"
      width="700px"
    >
      <br />
      <p>{{ t('HS001.ePassWarningTitle') }}</p>
      <br />
      <ul>
        <li>{{ t('HS001.ePassWarningItem1') }}</li>
        <li>{{ t('HS001.ePassWarningItem2') }}</li>
        <li>{{ t('HS001.ePassWarningItem3') }}</li>
      </ul>
    </a-modal>
  </div>
</template>
<script setup>
  import { ref, onMounted, computed } from 'vue'
  import { InfoCircleOutlined } from '@ant-design/icons-vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { accountExpiredCheck } from '../common/accountExpiredCheck.js'
  import {
    getStationMethod,
    postStationMethod,
    getStationSubjectMenu,
    postStationSubjectMenu,
  } from '@/api/station'

  const checkEPass = ref(false)
  const checkMyKad = ref(false)
  const checkNFCCard = ref(false)
  const checkMemberList = ref(false)

  // below not support yet
  const checkRocId = ref(false)
  const checkMobilePhone = ref(false)
  const checkCustomId = ref(false)

  const isShowEpassWarning = ref(false)

  const { t } = useI18n()

  const store = useStore()

  const expiredTs = computed(() => store.state.user.expiredTs)

  const onSave = () => {
    if (checkCount.value === 0) return
    postStationMethod({
      CUSTOM_ID: checkCustomId.value,
      NFC_CARD_NUMBER: checkNFCCard.value,
      MOBILE_PHONE: checkMobilePhone.value,
      ROC_ID: checkRocId.value,
      MY_KAD: checkMyKad.value,
      E_PASS: checkEPass.value,
    })
    postStationSubjectMenu({
      value: checkMemberList.value,
    })
    console.log('onSave')
  }
  const onCancel = async () => {
    await initCurrentSetting()
    console.log('onCancel')
  }

  onMounted(async () => {
    accountExpiredCheck(expiredTs.value)
    await initCurrentSetting()
  })

  const checkCount = computed(() => {
    let count = 0
    if (checkEPass.value) count++
    if (checkMyKad.value) count++
    if (checkNFCCard.value) count++
    if (checkMemberList.value) count++
    return count
  })

  const isDisableSave = computed(() => {
    return checkCount.value === 0
  })

  const getBgImgUrl = () => {
    if (checkCount.value === 1) {
      if (checkEPass.value) {
        return '/img/setting/id-img-bg-1-epass.png'
      } else if (checkMyKad.value) {
        return '/img/setting/id-img-bg-1-mykad.png'
      } else if (checkNFCCard.value) {
        return '/img/setting/id-img-bg-1-nfc.png'
      } else if (checkMemberList.value) {
        return '/img/setting/id-img-bg-1-member.png'
      } else {
        return '/img/setting/setting_id_bg.png'
      }
    } else if (checkCount.value === 2) {
      return '/img/setting/id-img-bg-2.png'
    } else if (checkCount.value === 3) {
      return '/img/setting/id-img-bg-3.png'
    } else if (checkCount.value === 4) {
      return '/img/setting/id-img-bg-4.png'
    } else {
      return '/img/setting/setting_id_bg.png'
    }
  }

  const getCheckedImage = (idx) => {
    const checkedImage = []
    if (checkCount.value == 4) {
      if (checkEPass.value) {
        checkedImage.push('/img/setting/4-epass.png')
      }
      if (checkMyKad.value) {
        checkedImage.push('/img/setting/4-myKad.png')
      }
      if (checkNFCCard.value) {
        checkedImage.push('/img/setting/4-nfc.png')
      }
      if (checkMemberList.value) {
        checkedImage.push('/img/setting/4-member.png')
      }
      return checkedImage.length > idx ? checkedImage[idx] : null
    } else if (checkCount.value > 1) {
      if (checkEPass.value) {
        checkedImage.push('/img/setting/3-epass.png')
      }
      if (checkMyKad.value) {
        checkedImage.push('/img/setting/3-myKad.png')
      }
      if (checkNFCCard.value) {
        checkedImage.push('/img/setting/3-nfc.png')
      }
      if (checkMemberList.value) {
        checkedImage.push('/img/setting/3-member.png')
      }
      return checkedImage.length > idx ? checkedImage[idx] : null
    } else {
      return null
    }
  }

  const showEpassWarning = () => {
    console.log('showEpassWarning')
    isShowEpassWarning.value = true
  }
  const handleOk = () => {
    isShowEpassWarning.value = false
  }

  const initCurrentSetting = async () => {
    const stationMethod = await getStationMethod()
    //     {
    //     "ok": true,
    //     "methods": {
    //         "CUSTOM_ID": true,
    //         "NFC_CARD_NUMBER": true,
    //         "MOBILE_PHONE": true,
    //         "ROC_ID": false,
    //         "MY_KAD": true,
    //         "E_PASS": true
    //     }
    // }

    if (stationMethod?.methods?.ROC_ID) {
      checkRocId.value = true
    } else {
      checkRocId.value = false
    }
    if (stationMethod?.methods?.MOBILE_PHONE) {
      checkMobilePhone.value = true
    } else {
      checkMobilePhone.value = false
    }
    if (stationMethod?.methods?.CUSTOM_ID) {
      checkCustomId.value = true
    } else {
      checkCustomId.value = false
    }

    if (stationMethod?.methods?.E_PASS) {
      checkEPass.value = true
    } else {
      checkEPass.value = false
    }
    if (stationMethod?.methods?.MY_KAD) {
      checkMyKad.value = true
    } else if (stationMethod?.methods?.NFC_CARD_NUMBER) {
      checkNFCCard.value = true
    } else {
      checkNFCCard.value = false
    }

    const stationSubjectMenu = await getStationSubjectMenu()
    // {"ok":true,"value":true}
    if (stationSubjectMenu?.value === true) {
      checkMemberList.value = true
    } else {
      checkMemberList.value = false
    }
  }
</script>
<style lang="less" scoped>
  .measure-thresthold-container {
    padding-top: 22px;
    background-color: #e5e5e5;

    .setting-container {
      margin: 0 auto;
      padding-top: 36px;
      width: 935px;
      height: 603px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);

      .setting-title {
        width: 850px;
        height: 59px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #4dbaa7;
        margin-top: 0px;
        margin-left: 42px;
        margin-right: 42px;
        color: #fff;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 110% */
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .setting-line {
        width: 850.015px;
        height: 0.8px;
        margin-top: 15px;
        background: #4dbaa7;
        margin-left: 42px;
        margin-right: 42px;
      }
      .setting-warning {
        color: #eb6264;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        padding-top: 15px;
        padding-left: 69px;
      }
      .setting-content {
        padding-top: 10px;
        .option-area {
          padding-left: 42px;
          padding-top: 10px;
          .ant-checkbox-wrapper {
            margin-right: 20px;
            color: #838383;
            font-family: 'Noto Sans TC';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 30px */
            letter-spacing: -0.38px;
          }
          .option-up {
            margin-top: 20px;
          }
          .option-line {
            width: 310px;
            height: 0.8px;
            margin-top: 30px;
            background: #4dbaa7;
            //margin-left: 42px;
            // margin-right: 100px;
          }
          .member-tip {
            color: #838383;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            margin-top: 26px;
            margin-left: 20px;
          }
          .member-check {
            color: #838383;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 80% */
            letter-spacing: 0.4px;
            text-transform: capitalize;
            margin-top: 16px;
          }
        }
        .option-preview {
          .preview-text {
            color: #838383;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
            width: 458px;
          }
        }
      }
      .setting-image-content {
        position: relative;
        width: 458px;
        height: 260px;
        .setting-image-bg {
          width: 100%;
          height: 100%;
        }
        .image-2-1 {
          position: absolute;
          top: 41px;
          left: 80px;
          width: 125px;
          height: 145px;
        }
        .image-2-2 {
          position: absolute;
          top: 41px;
          left: 254px;
          width: 125px;
          height: 145px;
        }
        .image-3-1 {
          position: absolute;
          top: 41px;
          left: 41px;
          width: 125px;
          height: 145px;
        }
        .image-3-2 {
          position: absolute;
          top: 41px;
          left: 166px;
          width: 125px;
          height: 145px;
        }
        .image-3-3 {
          position: absolute;
          top: 41px;
          left: 292px;
          width: 125px;
          height: 145px;
        }
        .image-4-1 {
          position: absolute;
          top: 58px;
          left: 36px;
          width: 125px;
          height: 145px;
        }
        .image-4-2 {
          position: absolute;
          top: 58px;
          left: 132px;
          width: 125px;
          height: 145px;
        }
        .image-4-3 {
          position: absolute;
          top: 58px;
          left: 230px;
          width: 125px;
          height: 145px;
        }
        .image-4-4 {
          position: absolute;
          top: 58px;
          left: 328px;
          width: 125px;
          height: 145px;
        }
      }
    }
    .button-area {
      .cancel-btn {
        margin-top: 50px;
        margin-left: 327px;
        width: 135px;
        height: 40px;
        background: var(--Gray-4, #bdbdbd);
        border-radius: 5px;
        color: var(--100, #f7fafc);
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .save-btn {
        margin-top: 50px;
        margin-left: 35px;
        width: 135px;
        height: 40px;
        background: #4dbaa7;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .v-enter-to,
  .v-leave-from {
    opacity: 1;
  }
</style>
